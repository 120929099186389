module services {
    export module logistics {
        export class commercialInvoiceCountryGroupService implements interfaces.logistics.ICommercialInvoiceCountryGroupService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }

            remove(ccgId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "CommercialInvoiceCountryGroup/Delete", {
                    ccgId: ccgId
                });
            }
        }
    }
    angular.module("app").service("commercialInvoiceCountryGroupService", services.logistics.commercialInvoiceCountryGroupService);
}